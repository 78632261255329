.static-view {
	a {
		font-weight: 700;
	}
}

.Insel-v1 {
	.inner-banner {
		background-image: url(RESOURCE/img/dieInsel001.jpg);
		background-position: 50% 30%;
	}

}

.angebote-v1 {
	.img-thumbnail {
		width: 100%;
		border: 0;
	}
}
.blogentry-v1 {
	margin-top: var(--page-margin-top);

	.img-thumbnail {
		height: 100% !important;

		@media (max-width:767px) {
			height: auto !important;
		}
	}


	.img-col {
		position: relative;

		.percent {
			position: absolute;
			left: 0px;
			font-size: 20px;
			font-weight: bold;
			color: var(--color-white);
			background-color: var(--color-red);
			padding: 10px;
		}
	}

	.tags {
		padding: 0;

		li {
			display: inline-block;
			padding-right: 5px;
		}
	}

	.date {
		color: var(--color-white);
		background-color: var(--color-primary);
		padding: 3px 5px;
	}


	.offer-title {
		padding: 10px 0px;
		font-size: 1.2rem;
		font-weight: bold;
	}

}
.privacy-v1 {
	margin-top: var(--page-margin-top);
	padding-top: var(--page-padding-top);

	ol {
		list-style: auto;
		padding-left: 20px;
	}

	ul {
		list-style: disc;
		padding-left: 20px;
	}
}
.header-boxes {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 30;
}

#header-fastnav-search {
	transition: top 0.5s ease;
	position: absolute;
	top: -100px;
	background-color: var(--color-bg-light);
	z-index: 10;
	right: 0px;

	&.show {
		top: var(--page-margin-top);
	}


	.search-unit-ul {
		max-width: 280px;
		padding: 8px 15px;

		.close {
			text-align: right;
			margin-top: -10px;
			cursor: pointer;
			margin-bottom: -3px;
		}
	}

	.input-search-unit {
		width: 250px;


		.form-control {
			padding: 5px 10px;
			width: 200px;

		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #333;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
		}
	}
}

#tele-box {
	transition: top 0.5s ease;
	position: absolute;
	top: -700px;
	background-color: var(--color-bg-light);
	z-index: 10;
	right: 0px;

	&.show {
		top: var(--page-margin-top);
	}

	.phone-box {
		padding: 10px;
		position: relative;

		.close {
			position: absolute;
			top: 5px;
			right: 5px;
			cursor: pointer;
		}
	}

	.fa-sharp {
		padding-right: 5px;
	}

	.phoneLink {
		text-decoration: underline;
		cursor: pointer;

	}
}

#settings {
	transition: top 0.5s ease;
	position: absolute;
	top: -700px;
	background-color: var(--color-bg-light);
	z-index: 10;
	right: 0px;

	&.show {
		top: var(--page-margin-top);
	}

	.settings-box {
		padding: 10px;
		position: relative;

		.close {
			position: absolute;
			top: 5px;
			right: 5px;
			cursor: pointer;
		}
	}

	.fa-sharp {
		padding-right: 5px;
	}

	.link {
		cursor: pointer;

	}
}


.navbar-v1.affix,
.navbar-v1 {
	padding: 30px 0;
	position: fixed;
	width: 100%;
	top: 0;
	border-width: 0 0 1px;
	height: 100px;

	z-index: 50;
	box-shadow: 0 3px 3px -3px grey;
	background-color: var(--nav-bg-color);

	@media (max-width:768px) {
		position: fixed;
		height: 75px;
	}


	.fav-count {
		position: relative;

		.sub-count {
			-moz-border-radius: 50%;
			-webkit-border-radius: 50%;
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 7px;
			width: 18px;
			height: 18px;
			background: var(--color-secondary);
			color: var(--color-white);
			text-align: center;
			font-size: 12px;
			line-height: 18px;

			@media (min-width:1400px) {
				right: 6px;
			}

			@media (min-width:1800px) {
				right: 10px;
			}

			@media (max-width:1399px) {
				right: 4px;
			}

			@media (max-width:1199px) {
				right: 2px;
			}

			@media (max-width:992px) {
				right: -2px;
			}


			@media (max-width:767px) {
				width: 15px;
				height: 15px;
				line-height: 15px;
				right: -1px;
			}
		}
	}

	.tele-box,
	.navbar-v1.affix .search-unit-ul,
	.navbar-v1.affix .tele-box {
		position: absolute;
		right: 0;
		top: 77px;
		background-color: var(--color-grey-light);
		max-width: 280px;
		padding: 8px 32px;
		height: 40px;
		font-weight: 600;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}



	.header-main {

		#logo {
			transition: all 0.3s ease;
			/* Füge eine Übergangsanimation hinzu */
		}

		.header-logo {
			position: absolute;
			z-index: 20;
			top: 5px;

			&.small {
				/*@media (min-width:993px) {
					top: 10px;
				}*/
			}

			@media (max-width:992px) {
				top: 10px;
			}

			.logo {
				width: 200px;
				height: auto;

				@media (max-width:992px) {
					width: 140px;
				}

				&.small {
					@media (min-width:993px) {
						width: 57px;
					}
				}


			}

		}

		.main-menu {
			float: right;

			@media (max-width:992px) {
				position: absolute;
				left: 0;
				top: 76px;
				background-color: var(--main-nav-mob-menu-bg-color);
				z-index: 5;
			}

			>ul {
				padding: 0;
				margin: 0;

				>li {
					float: left;
					display: inline-block;

					@media (max-width:992px) {
						display: block;
						width: 100%;
					}

					&:last-child {
						margin-right: 0;
					}


					>a,
					button {
						color: var(--main-nav-font-color);
						text-transform: var(--main-nav-text-transform);
						font-size: var(--main-nav-font-size);
						padding: 15px 1.2vW;
						line-height: 1;
						display: block;
						font-weight: 600;
						border: none;
						background-color: transparent;

						@media (max-width: 991px) {
							font-size: var(--main-nav-font-size);
							color: var(--color-white);
						}

						@media (max-width:992px) {
							padding: 10px 15px;
							text-transform: capitalize;
							font-size: var(--main-nav-font-size);
							color: var(--main-nav-mob-font-color-hover);
							width: 100%;
							text-align: left;
						}


						&:hover {
							color: var(--main-nav-font-color-hover);
							background-color: var(--main-nav-bg-hover);
							text-decoration: none;
						}

						&:focus {
							color: var(--main-nav-font-color-hover);
							background-color: var(--main-nav-bg-hover);
							text-decoration: none;
						}



					}

					&.dropdown {

						cursor: pointer;

						.dropdown-backdrop {
							display: none;
						}


						&.show {
							background-color: var(--main-nav-bg-hover);

							a {
								color: var(--main-nav-font-color-hover);
							}
						}
					}




				}
			}

			.btn {
				@media (max-width:340px) {
					font-size: 12px;
					padding: 8px 8px;
				}
			}
		}
	}

	.dropdown-menu {
		border-radius: 0;
		border: none;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		min-width: 200px;
		margin-top: -1px;

		@media (max-width: 992px) {
			width: 100% !important;
			max-height: 450px;
			overflow: scroll;
		}
	}

	.sub-nav {

		ul {
			list-style-type: none;
			padding-left: 10px;

			@media (min-width: 993px) {
				display: flex;
				flex-wrap: wrap;
				width: 250px;
			}

			@media (max-width: 992px) {
				width: 100%;
			}


			li {
				@media (min-width: 993px) {
					width: 100%;
				}

				a {
					padding: 10px 0;
					color: var(--main-sub-font-color) !important;
					display: block;
					font-size: 17px;

					@media (max-width:992px) {
						padding: 10px;
						font-weight: 400;
						text-transform: initial;
					}

					&:hover {
						color: var(--main-sub-font-color-hover) !important;
						text-decoration: none;
					}

					&:focus {
						color: var(--main-sub-font-color-hover) !important;
						text-decoration: none;
					}
				}

			}
		}


	}

	.icon {
		display: none;
		position: absolute;
		right: 10px;
		text-decoration: none;
		color: var(--color-secondary);
		font-size: 30px;

	}

	.navactive {
		@media (min-width:993px) {
			color: var(--main-nav-font-color-hover) !important;
			background-color: var(--main-nav-bg-hover) !important;
		}
	}

	.nav-row {


		.col-12 {
			display: flex;
			justify-content: flex-end;

			.icons-nav {
				display: flex;
				align-items: center;
				margin-bottom: 0;
				position: relative;

				@media screen and (max-width: 992px) {
					position: absolute;
					right: 45px;
					top: 35px;
				}

				li {
					&.btn-call {
						cursor: pointer;

						@media(max-width:992px) {
							padding: 0 10px;
						}

						a {
							&:hover {
								color: var(--color-grey-dark);
							}

							&:focus {
								color: var(--color-grey-dark);
							}
						}
					}

					a {
						padding: 15px 1.2vW;
						font-size: var(--main-nav-font-size);
					}
				}
			}
		}
	}


	@media screen and (max-width: 992px) {

		.top-nav {
			padding: 10px 0 8px;
			text-align: right;


			.input-search-unit {
				display: none;
			}
		}

		.header-logo {
			padding-left: 0px !important;
		}

		.nav-row {
			min-height: 50px !important;
		}

		.icon {
			display: block;
			bottom: 10px;
		}

		.dropdown-menu {
			position: relative !important;
			transform: none !important;
			width: 65px;
			border-top: none;
		}

		.main-menu {
			background-color: var(--nav-bg-color);
			padding: 0px 0;
			z-index: 50;
			display: none;
			width: 100%;
			border-bottom: 1px solid #61847a;
		}
	}

	@media screen and (max-width: 992px) {

		.top-nav {
			text-align: center;

			.input-search-unit {
				display: none;
			}
		}

		.header-main {
			.nav-row {
				min-height: 20px !important;
			}

			.icon {
				position: absolute;
				top: 21px;
				right: 10px;
			}
		}

	}

	@media screen and (min-width: 993px) {
		.main-menu {
			display: block !important;
		}
	}

}

.to-top {
	position: fixed;
	cursor: pointer;
	bottom: 15px;
	display: none;
	right: 10px;
	width: 32px;
	height: 32px;
	border-radius: var(--border-radius);
	background-color: var(--color-secondary);
	color: var(--color-white);
	z-index: 200;
	line-height: 32px;
	text-align: center;
	font-size: 24px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

}